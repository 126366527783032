<template>
  <b-card>
    <b-card-header>
      <h5 class="mb-3"> Definir o endereço gratuito de seu site </h5>
      <p> Para que você possa ser encontrado, é necessário possuir um endereço na web. </p>
      <p>
        Configure um subdomínio gratuito, informando um nome único (apelido), que será
        vinculado à sua conta e publicado como subdomínio acessível para seus clientes.
      </p>
      <p>
        Siga algumas regras para configurar seu domínio:
      </p>
      <ul>
        <li> Não utilize espaços; </li>
        <li> Dê preferência ao uso de letras, sem possível sem utilizar números; </li>
        <li> A palavra deve possuir entre {{ minLength }} e {{ maxLength }} caracteres;</li>
        <li> Seja criativo, pois este será seu endereço. </li>
      </ul>
    </b-card-header>
    <b-card-body>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          id="setup-input-username"
          placeholder="Informe seu nickname com uma palavra"
          v-model="username"
          :minlength="minLength"
          :maxLength="maxLength"
          @keyup="checkKey"
        >
          <button
            class="btn"
            type="button"
            :class="`btn-${status}`"
            @click="doApply"
            :disabled="status !== 'success'"
          >
            {{ btnMessage }}
          </button>
      </div>
    </b-card-body>
    <b-card-footer>
      <div v-if="result && result?.available && result?.domain">
        Este será o endereço de seu site: <strong> {{ result.domain }} </strong>
      </div>
      <div v-else-if="currentSubdomain">
          Seu endereço atual é:
          <a :href="currentSubdomain" target="_blank">
            <strong> {{ currentSubdomain }} </strong>
          </a>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { debounce } from '@/utils'
import { applySubdomain, checkSubdomain, checkWizard } from './api'
import Notify from '@/notify'
import { useRouter } from 'vue-router'
import storage from '@/storage'

export default {
  setup() {
    const minLength = 5
    const maxLength = 25
    const username = ref(null)
    const checking = ref(false)
    const status = ref('info')
    const result = ref(null)
    const router = useRouter()
    const currentSubdomain = ref(null)
    const user = storage.getItem('user')
    const exprStr = /[~!@#$%^&*.¨()_\-+={}[\]|"':;?,/><,\\ ]/g

    const autoResolveInitSubdomain = () => {
      if (!user) return

      if (Array.isArray(user.domains) && user.domains.length > 0) {
        currentSubdomain.value = user.domains[0]
      } else {
        username.value = user.name
          .replace(exprStr,'')
          .toLowerCase()
      }
    }

    const runChecker = debounce(() => {
      if (
        username.value &&
        username.value.length >= minLength &&
        username.value.length <= maxLength
      ) {
        checking.value = true
        checkSubdomain(username.value).then((data) => {
          status.value = data.available ? 'success' : 'danger'
          result.value = data
        }).finally(() => {
          checking.value = false
        })
      } else {
        status.value = 'info'
      }
    }, 600)

    const doApply = () => {
      if (
        result.value &&
        result.value.available &&
        result.value.domain
      ) {
        applySubdomain(username.value).then((data) => {
          if (user) {
            if (!Array.isArray(user.domains)) {
              user.domains = []
            }
            user.domains[0] = data.domain
            storage.set('user', user)
          }

          Notify.create({
            position: 'top-end',
            icon: 'success',
            title: 'Subdomínio configurado!',
            showConfirmButton: false,
            timer: 1500,
            willClose() {
              checkWizard().then((data) => {
                const keys = Object.keys(data)
                if (Array.isArray(keys) && keys.length > 0) {
                  router.push({
                    name: 'wizard_stages',
                    params: {
                      stage: keys[0]
                    }
                  })
                } else {
                  router.push({ name: 'home' })
                }
              })
            }
          })
        })
      }
    }

    const checkKey = () => {
      username.value = username.value
        .replace(exprStr,'')
        .toLowerCase()
    }

    const btnMessage = computed(() => {
      switch(status.value) {
        case 'primary':
          return 'Verificando... Aguarde.'
        case 'success':
          return 'Disponível! Clique aqui para utilizar.'
        case 'danger':
          return 'Indisponível! Informe outro nome.'
        default:
          return 'Aguardando. Informe um nome válido.'
      }
    })

    watch(username, runChecker)

    onMounted(() => {
      autoResolveInitSubdomain()
    })

    return {
      username,
      checking,
      status,
      btnMessage,
      doApply,
      checkKey,
      result,
      minLength,
      maxLength,
      currentSubdomain
    }
  }
}
</script>
