import { axios } from '@/axios'

export const applySubdomain = (username) => {
  return axios.post('/seller/account/actions/apply-username', { username }).then(({ data }) => data)
}

export const checkSubdomain = (username) => {
  return axios.post('/seller/account/actions/check-username', { username }).then(({ data }) => data)
}

export const checkWizard = () => {
  return axios.get('/seller/account/wizard').then(({ data }) => data)
}

export const getBankAccount = (id) => {
  return axios.get(`/bank-accounts/${id}`).then(({ data }) => data)
}

export const createBankAccount = (params = {}) => {
  return axios.post('/bank-accounts', params).then(({ data }) => data)
}

export const updateBankAccount = (id, params = {}) => {
  return axios.put(`/bank-accounts/${id}`, params).then(({ data }) => data)
}
