<template>
  <Layout :pagetitle="'Configurações da conta'">
    <div class="row">
      <div class="col-8 offset-2">
        <component :is="$route.params.stage" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import Subdomain from './subdomain'
import BankAccount from './bank-account'

export default {
  components: {
    Layout,
    BankAccount,
    Subdomain
  },
  setup() {
    return {
    }
  }
}
</script>
