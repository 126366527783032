<template>
  <b-card>
    <b-card-header>
      <div style="width: 100%; background: #fff; opacity: .9; border-radius: 1rem; text-align: center; padding: 1rem; margin-bottom: 1rem;">
        <a href="https://paggue.io" target="_blank">
          <img src="https://paggue.io/img/logo.png" />
        </a>
        <h5 class="mt-3" style="color: #444;"> Definir conta paggue para cobrança </h5>
      </div>
      <p>
        Para a melhor experiência de seus clientes no momento da compra,
        utilizamos o recurso de processamento instantâneo de pagamentos via PIX.
      </p>
      <p>
        Atualmente, suportamos o pagamento via Paggue, onde você deverá criar sua conta
        e obter as credenciais de acesso da API (key e secret).
      </p>
      <p>
        Siga os passos para obtenção dessa informações:
      </p>
      <ul>
        <li> Formalize sua conta na Paggue; </li>
        <li> Solicite as credenciais de API; </li>
        <li> Cadastre a conta abaixo, utilizando os dados fornecidos. </li>
      </ul>
      <p> O nome da conta é apenas um identificador visual. </p>
      <b-alert show style="font-weight: bold;">
        Vale lembrar que tanto a Paggue quanto a Plataforma Supra Sorte possuem taxas distintas.
        Para desbloquear sua conta, ative a taxa de administração, falando com o suporte.
        <hr />
        Ao modificar as credenciais da conta (Key e Secret), esta permanecerá bloqueada para uso,
        até que seja avaliada e liberada pelos administradores.
      </b-alert>
    </b-card-header>
    <b-card-body>
      <b-alert
        v-if="bankAccount.id && !bankAccount.account_verified_at"
        variant="danger"
        show
      >
        Esta conta ainda não pode ser utilizada, pois não passou por validação de taxa administrativa
      </b-alert>
      <b-alert
        v-else-if="!bankAccount.id"
        variant="danger"
        show
      >
        Esta conta deverá passar por uma validação administrativa antes de ser utilizada
      </b-alert>
      <form @submit.prevent="save">
        <div class="row mb-3" v-for="field in fields" :key="field.key">
          <label for="wizard-bank-account-name" class="col-sm-3 col-form-label">
            {{ field.label }}
          </label>
          <div class="col-sm-9">
            <input
              required
              v-model="bankAccount[field.key]"
              type="text"
              class="form-control"
              :placeholder="field.label"
              id="wizard-bank-account-name"
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 text-end">
            <button
              class="btn btn-success"
              type="submit"
              style="width: 150px;"
            >
              <i
                class="bx font-size-16 align-middle me-2"
                :class="saving ? 'bx-loader bx-spin' : 'bx-check'"
              ></i>
              {{ saving ? 'Salvando' : 'Salvar conta' }}
            </button>
          </div>
        </div>
      </form>
    </b-card-body>
  </b-card>
</template>

<script>
import { onMounted, ref } from 'vue'
import {
  checkWizard,
  getBankAccount,
  createBankAccount,
  updateBankAccount
} from './api'
import Notify from '@/notify'
import { useRoute, useRouter } from 'vue-router'
import { Toasts } from '../../../utils/toast-alerts'

const fields = [
  {
    key: 'name',
    label: 'Nome da conta',
  },
  {
    key: 'client_key',
    label: 'Client Key',
  },
  {
    key: 'client_secret',
    label: 'Client Secret',
  }
]

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const saving = ref(false)
    const bankAccount = ref({
      id: null,
      name: null,
      client_key: null,
      client_secret: null,
      account_verified_at: null
    })

    const save = () => {
      if (
        bankAccount.value.name &&
        bankAccount.value.client_key &&
        bankAccount.value.client_secret
      ) {
        saving.value = true

        if (bankAccount.value.id) {
          updateBankAccount(bankAccount.value.id, bankAccount.value).then((data) => {
            populateModel(data)
            Toasts('success', 'Conta salva com sucesso!')
          }).catch(e => {
            Toasts('error', e.response?.data?.message)
          }) .finally(() => {
            saving.value = false
          })
        } else {
          createBankAccount(bankAccount.value).then((data) => {
            populateModel(data)
            Notify.create({
              position: 'top-end',
              icon: 'success',
              title: 'Conta salva com sucesso!',
              showConfirmButton: false,
              timer: 1500,
              willClose() {
                checkWizard().then((data) => {
                  const keys = Object.keys(data)
                  if (Array.isArray(keys) && keys.length > 0) {
                    router.push({
                      name: 'wizard_stages',
                      params: {
                        stage: keys[0]
                      }
                    })
                  } else {
                    router.replace({
                      name: 'wizard_stages',
                      params: {
                        stage: 'bank-account'
                      },
                      query: {
                        id: bankAccount.value.id
                      }
                    })
                  }
                })
              }
            })
          }).catch((e) => {
            console.log(e.response.data.errors)
          }).finally(() => {
            saving.value = false
          })
        }
      }
    }

    const populateModel = (data) => {
      bankAccount.value = {
        id: data.id,
        name: data.name,
        client_key: data?.gateway_data?.client_key,
        client_secret: data?.gateway_data?.client_secret,
        account_verified_at: data?.account_verified_at
      }
    }

    onMounted(() => {
      if (route.query.id) {
        getBankAccount(route.query.id).then(populateModel)
      }
    })

    return {
      fields,
      bankAccount,
      save,
      saving
    }
  }
}
</script>
